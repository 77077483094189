
<script>
    import { storeToRefs } from "pinia";  
    import { useloggedUserInfo,moduleStore} from "./stores/UserInfo";
    import ConfirmModalComponent from '@/assets/common/ConfirmModalComponent.vue';
    import AlertMessage from "@/assets/common/AlertMessage.vue";
    import NetworkPopup from '@/components/NetworkPopup.vue';
export default {
  name:'App',
  components: {
      ConfirmModalComponent,
      AlertMessage,
      NetworkPopup
    },
  data(){
    return{
       showAddWizard:false,
       showAddBookMark:false,
       showNofication:false,
       wizardType:'',
       controllerName:null,
       actionName:null,
       isShowMenuHeader:true
       
    }
  },
 
    setup() {
        const store = useloggedUserInfo();
        const modulestore = moduleStore();
        const { GetUserInfo } = storeToRefs(store);
        const { GetModuleId } = storeToRefs(modulestore);
        return { GetUserInfo, GetModuleId };
    },
}
</script>
<template>
  <section>
    <!-- <link rel="stylesheet" href="https://talygenv.github.io/Vue-UI-Content/css/emojionearea.css">
  <link rel="stylesheet" href="https://talygenv.github.io/Vue-UI-Content/css/unified_communicationNew.css"> -->
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.0/css/all.min.css"/>
    <!-- <ModuleHeader v-if="(GetUserInfo != null && isShowMenuHeader)" :modulemasterid="String(GetModuleId)"></ModuleHeader>    -->
    <section id="main-content" class="main-content">
      <ConfirmModalComponent ref="confirmModal" />
      <NetworkPopup ref="networkPopup" />
      <AlertMessage ref="AlertMessage" />
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 p-0">
                      <router-view/>
                    </div>
                </div>
            </div>
            <tooltip  :width="250"/>
        </section>
  </section>       
</template>
<style>
/* @import "@/assets/base.css"; */

@media (min-width: 1024px) {
  body {
    display: flex;
    place-items: center;
  }
}
a{text-decoration:none;}

</style>
