<template>
    <div  v-if="popupVisible" class="network-popup">
      <p>Network connection is slow. Please check your connection.</p>
      <button @click="dismiss">Dismiss</button>
    </div>

  </template>
  
  <script>
import networkState from '../http-common'; // Import the reactive state

export default {
    computed: {
    popupVisible() {
      return networkState.networkState.popupVisible;
    },
  },
  methods: {
    dismiss() {
        networkState.networkState.isSlow = false; 
        networkState.networkState.popupVisible = false; // Update the shared state
    },
  },
 
};
</script>
  
  <style scoped>
  .network-popup {
    position: fixed;
    /* top: 20px; */
    left: 50%;
    transform: translateX(-50%);
    background: #ffcccb;
    color: #333;
    padding: 10px 20px;
    /* border: 1px solid #f44336; */
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  button {
    margin-top: 10px;
    padding: 5px 10px;
    border: none;
    background-color: #f44336;
    color: white;
    border-radius: 3px;
    cursor: pointer;
  }
  button:hover {
    background-color: #d32f2f;
  }
  </style>
  